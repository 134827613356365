<template>
  <footer class="z-index-0 mt-md-0 mt-5">
    <div class="bg-white">
      <div class="container position-relative py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>© {{ $t('footer.title') }}</div>
          <div class="position-relative">
            <router-link
              :to="{ name: 'privacy-policy' }"
              tag="a"
              class="pl-sm-2 d-sm-inline d-block"
            >
              {{ $t('generic.privacyPolicy') }}
            </router-link>

            <router-link
              :to="{ name: 'cookie-policy' }"
              tag="a"
              class="pl-sm-2 d-sm-inline d-block"
            >
              {{ $t('cookiePolicyPage.title') }}
            </router-link>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary floating-btn floating-btn--chat"
          @click="openFreshChat"
        >
          <span class="d-inline-flex align-items-center">
            {{ $t('footer.consultationCTA') }}
          </span>
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  methods: {
    openFreshChat() {
      if (window.fcWidget.isLoaded()) {
        document.querySelector('#fc_frame').style.display = 'block';
      }
      window.fcWidget.open();
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  font-size: 12px;
  .floating-btn {
    position: absolute;
    bottom: 110%;
    right: 0;
    padding: 6px 46px 6px 20px;
    &--chat:after {
      content: '';
      background-image: url('/images/svg/chat_icon.svg');
      height: 16px;
      width: 16px;
      display: inline-flex;
      background-repeat: no-repeat;
      line-height: 1;
      margin-left: auto;
      position: absolute;
      right: 20px;
    }
  }
}
</style>
